import React from "react"
import Layout from "../layout/GeneralLayout"
import {
  Box,
  Heading,
  Paragraph,
  GlassBox,
  Anchor,
} from "../components/commonElements"
import styled from "styled-components"
import Footer from "../components/footer"
import SEO from "../components/seo"
import AccountForm from "../components/accountForm"

const OuterContainer = styled(Box)`
  width: 100%;
  align-items: center;
  min-height: 78vh;
  @media ${props => props.theme.device.tablet} {
    min-height: 83vh;
  }
`

const Container = styled(GlassBox)`
  font-size: 1.8rem;
  max-width: 70rem;
  align-items: center;
  width: calc(100% - 2rem);
`

const InputBox = styled(Box)`
  flex: 2;
  max-width: 80rem;
  width: 100%;
  @media (max-width: 1200px) {
    flex: 100%;
  }
`

const signup = () => {
  return (
    <Layout>
      <Box flex="1" mt="1rem" alignItems="center" gap="1rem">
        <OuterContainer>
          <Container
            backgroundColor="white"
            padding="2rem 2rem 1.5rem"
            minHeight="48.8rem"
          >
            <Heading level={1} textAlign="center" mb=".8rem">
              Join us.
            </Heading>
            <Paragraph level={4} textAlign="center" mb="0">
              Sign up to save & record all your customizations.
            </Paragraph>
            <Paragraph level={4} textAlign="center" mb="2rem">
              Already a user?&nbsp;
              <Anchor
                href="/login/"
                style={{
                  textDecoration: `underline`,
                }}
                alt="login"
              >
                LOGIN
              </Anchor>
            </Paragraph>
            <InputBox>
              <AccountForm isSignUp={true} />
            </InputBox>
          </Container>
        </OuterContainer>
        <Footer />
      </Box>
    </Layout>
  )
}

export default signup

export const Head = () => (
  <SEO
    titleP="Sign Up | FlipSimu"
    descriptionP="This is the sign up page of FlipSimu"
    pathname="/signup/"
  />
)
